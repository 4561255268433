import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _createSvgIcon2 from "../../createSvgIcon";
import * as _CheckCircle3 from "@rsuite/icon-font/lib/legacy/CheckCircle";

var _CheckCircle2 = "default" in _CheckCircle3 ? _CheckCircle3.default : _CheckCircle3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(_createSvgIcon2);

var _CheckCircle = _interopRequireDefault(_CheckCircle2); // Generated by script, don't edit it please.


var CheckCircle = (0, _createSvgIcon["default"])({
  as: _CheckCircle["default"],
  ariaLabel: "check circle",
  category: "legacy",
  displayName: "CheckCircle"
});
var _default = CheckCircle;
exports["default"] = _default;
exports = exports.default;
export default exports;